import { useRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setDiscoverGenre } from "./discoverSlice";
import "./discoverGenreSelector.css";

export const DiscoverGenreSelector = ({ onGenreChange }) => {
  const [selectedGenre, setSelectedGenre] = useState(null);
  const [scrollAmount, setScrollAmount] = useState(570); // Default scroll amount
  const scrollRef = useRef(null);
  const dispatch = useDispatch();

  const genres = [
    "science fiction", "fantasy", "mystery", "thriller",'romance', "adventure", "horror",
    "historical fiction", "western", "literary", "dystopian",
    "steampunk", "space opera", "first contact", "post-apocalyptic",
    "alternative history", "epic", "urban fantasy", "magical realism",
    "cyberpunk", "superhero", "satire", "heist", "spy"
  ];

  useEffect(() => {
    dispatch(setDiscoverGenre(selectedGenre ? [selectedGenre] : []));
  }, [selectedGenre, dispatch]);

  useEffect(() => {
    const updateScrollAmount = () => {
      if (window.innerWidth <= 500) {
        setScrollAmount(276); // Adjust for mobile screens
      } else if (window.innerWidth <= 1100) {
        setScrollAmount(444); // Adjust for medium screens
      } else {
        setScrollAmount(570); // Default for large screens
      }
    };

    updateScrollAmount(); // Set initial value
    window.addEventListener("resize", updateScrollAmount);
    
    return () => window.removeEventListener("resize", updateScrollAmount);
  }, []);

  const handleGenreClick = (genre) => {
    setSelectedGenre((prevGenre) => (prevGenre === genre ? null : genre));
    onGenreChange && onGenreChange(selectedGenre ? [selectedGenre] : []);
  };

  const scroll = (direction) => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += direction === "left" ? -scrollAmount : scrollAmount;
    }
  };

  return (
    <div className="genre-selector-container">
      <div className="carousel-btn left" onClick={() => scroll("left")}>&lt;</div>

      <div className="scroll-wrapper">
        <div className="scroll-container" ref={scrollRef}>
          {genres.map((genre) => (
            <div
              key={genre}
              className={`genre-icon-container ${selectedGenre === genre ? "selected" : ""}`}
              onClick={() => handleGenreClick(genre)}
            >
              <img
                loading="lazy"
                className="genre-selector-icon"
                alt={genre}
                src={`/images/genre-icons/${genre}.png`}
                title={genre}
              />
              {selectedGenre === genre && <span className="dot-selector"></span>}
            </div>
          ))}
        </div>
      </div>

      <div className="carousel-btn right" onClick={() => scroll("right")}>&gt;</div>
    </div>
  );
};
